import axios from "axios";
import service from "../index";
import { toast } from "react-toastify";
import routes from "../routes";

// Request Interceptor
axios.interceptors.request.use(
  async (config) => {
    const token = service.getTokensLocalStorage();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response Interceptor
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // first error
    if (
      error.response.status === 401 &&
      error.response.statusText === "Unauthorized"
    ) {
      window.location.replace("/");
      toast.error("Login again");
    } else if (
      error.response.data?.error === "Token error: Token has expired" &&
      error.response.status === 500
    ) {
      window.location.replace("/");
      toast.error("Inactivity time elapsed, Login again");
    } else if (error.response.status === 400) {
      toast.error(error.response.message);
    }
    return Promise.reject(error);
  }
);

const api = {
  getAnnouncement: async () =>{
    return await axios.get(routes["get-announcement"]())
  },
  profile: {
    userProfile: async () => {
      return await axios.get(routes["my-profile"]());
    },
    updateProfile: async (postBody) => {
      return await axios.post(routes["update-profile"](), postBody);
    },
  },
  campaign: {
    sendSMS: async (smsType, postBody) => {
      return await axios.post(routes.campaign["send-sms"](smsType), postBody);
    },
    confirmSMS: async (smsType, postBody) => {
      return await axios.post(
        routes.campaign["confirm-sms"](smsType),
        postBody
      );
    },
    scheduleSMS: async (smsType, postBody) => {
      return await axios.post(
        routes.campaign["schedule-sms"](smsType),
        postBody
      );
    },
    confirmScheduleSMS: async (smsType, postBody) => {
      return await axios.post(
        routes.campaign["confirm-schedule-sms"](smsType),
        postBody
      );
    },
    getSentMessages: async () => {
      return await axios.get(routes.campaign["get-sent-messages"]());
    },
    getFailedMessages: async () => {
      return await axios.get(routes.campaign["get-failed-messages"]());
    },
    senderId: async (postBody) => {
      return await axios.post(routes.campaign["request-senderId"](), postBody);
    },
    getSenderId: async () => {
      return await axios.get(routes.campaign["get-senderId"]());
    },
    getCampaignHistory: async () => {
      return await axios.get(routes.campaign["get-campaignHistory"]());
    },
    getTransactionHistory: async () => {
      return await axios.get(routes.campaign["get-transactionHistory"]());
    },
    getEspeeBalance: async () => {
      return await axios.get(routes.campaign["get-espee-balance"]());
    },
    getLastSentMessage: async () => {
      return await axios.get(routes.campaign["get-last-sent-message"]());
    },
    sendTTS: async (postBody) => {
      return await axios.post(
        routes.campaign["send-voice-message"](),
        postBody
      );
    },
    getVoiceSMSRecords: async () => {
      return await axios.get(routes.campaign["get-voice-records"]());
    },
    getVirtualNumber: async () => {
      return await axios.get(routes.campaign["get-virtual-number"]());
    },
    getOwnNumber: async () => {
      return await axios.get(routes.campaign["get-own-number"]());
    },
    requestVirtualNumber: async (postBody) => {
      return await axios.post(
        routes.campaign["request-virtual-number"](),
        postBody
      );
    },
    getVoiceHistory: async () => {
      return await axios.get(routes.campaign["get-all-voice-history"]());
    },
    getSMSUNITPRICE: async () => {
      return await axios.get(routes["get-sms-unit-price"]());
    },
    getVoiceUNITPRICE: async () => {
      return await axios.get(routes["get-voice-unit-price"]());
    },
    getVirtualUNITPRICE: async () => {
      return await axios.get(routes["get-virtual-price"]());
    },
    getOwnNumberUNITPRICE: async () => {
      return await axios.get(routes["get-own-number-unit-price"]());
    },
  },
  contacts: {
    createGroup: async (postBody) => {
      return await axios.post(
        routes.contacts["create-contact-group"](),
        postBody
      );
    },
    getGroup: async () => {
      return await axios.get(routes.contacts["get-contact-group"]());
    },
    deleteGroup: async (id) => {
      return await axios.delete(routes.contacts["delete-contact-group"](id));
    },
    updateGroup: async (id, postBody) => {
      return await axios.put(
        routes.contacts["update-contact-group"](id),
        postBody
      );
    },
    createContactWithinGroup: async (id, postBody) => {
      return await axios.post(
        routes.contacts["create-contact-within-a-group"](id),
        postBody
      );
    },
    deleteContact: async (id) => {
      return await axios.delete(routes.contacts["delete-contact"](id));
    },
    updateContact: async (id, postBody) => {
      return await axios.put(routes.contacts["update-contact"](id), postBody);
    },
    getContactForSingleGroup: async (id) => {
      return await axios.get(
        routes.contacts["get-contact-for-single-group"](id)
      );
    },
    importToNewGroup: async (postBody) => {
      return await axios.post(
        routes.contacts["import-contact-to-new-group"](),
        postBody
      );
    },
    importToOldGroup: async (id, postBody) => {
      return await axios.post(
        routes.contacts["import-contact-to-old-group"](id),
        postBody
      );
    },
    getGroupWithContacts: async () => {
      return await axios.get(routes.contacts["get-group-with-contacts"]());
    },
    getContact: async () => {
      return await axios.get(routes.contacts["get-contact"]());
    },
  },
  payments: {
    initiatePayment: async (postBody) => {
      return await axios.post(routes.payments["make-payment"](), postBody);
    },
    confirmPayment: async (postBody) => {
      return await axios.post(routes.payments["confirm-payment"](), postBody);
    },
  },
  admin: {
    getAnnouncements: async ()=>{
      return await axios.get(routes.admin["get-announcement"]())
    },
    makeAnnouncement: async (postBody)=>{
      return await axios.post(routes.admin["make-announcement"](),postBody)
    },
    sendSMStoUser: async (postBody)=>{
      return await axios.post(routes.admin["send-sms-toUser"](),postBody)
    },
    getSenderIDs: async () => {
      return await axios.get(routes.admin["get-all-sender-ids"]());
    },
    approveID: async (id) => {
      return await axios.post(routes.admin["approve-sender-id"](id));
    },
    rejectID: async (id, postBody) => {
      return await axios.post(
        routes.admin["disapprove-senderid"](id),
        postBody
      );
    },
    deleteVirtualNumber: async (id) => {
      return await axios.delete(routes.admin["delete-virtual-number"](id));
    },
    approveVirtualNumber: async (id, postBody) => {
      return await axios.post(
        routes.admin["approve-virtual-number"](id),
        postBody
      );
    },
    setSMSAPIKEY: async (postBody) => {
      return await axios.post(routes.admin["set-sms-api-key"](), postBody);
    },
    setSMSUNITPRICE: async (postBody) => {
      return await axios.post(routes.admin["set-sms-unit-price"](), postBody);
    },
    setVoiceUNITPRICE: async (postBody) => {
      return await axios.post(routes.admin["set-voice-unit-price"](), postBody);
    },
    setVirtualUNITPRICE: async (postBody) => {
      return await axios.post(
        routes.admin["set-virtual-unit-price"](),
        postBody
      );
    },
    setOwnNumberUNITPRICE: async (postBody) => {
      return await axios.post(
        routes.admin["set-own-number-unit-price"](),
        postBody
      );
    },
    setESPEEAPIKEY: async (postBody) => {
      return await axios.post(routes.admin["set-espee-api-key"](), postBody);
    },
    setWalletAddress: async (postBody) => {
      return await axios.post(routes.admin["set-wallet-address"](), postBody);
    },
    getSMSAPIKEY: async () => {
      return await axios.get(routes.admin["get-sms-api-key"]());
    },
    getSMSUNITPRICE: async () => {
      return await axios.get(routes.admin["get-sms-unit-price"]());
    },
    getVoiceUNITPRICE: async () => {
      return await axios.get(routes.admin["get-voice-unit-price"]());
    },
    getVirtualUNITPRICE: async () => {
      return await axios.get(routes.admin["get-virtual-price"]());
    },
    getOwnNumberUNITPRICE: async () => {
      return await axios.get(routes.admin["get-own-number-unit-price"]());
    },
    getESPEEAPIKEY: async () => {
      return await axios.get(routes.admin["get-espee-api-key"]());
    },
    getWalletAddress: async () => {
      return await axios.get(routes.admin["get-wallet-address"]());
    },
    getVirtualNumbers: async () => {
      return await axios.get(routes.admin["get-virtual-numbers"]());
    },
    getOwnNumbers: async () => {
      return await axios.get(routes.admin["get-own-numbers"]());
    },

    setContactAddress: async (postBody) => {
      return await axios.post(routes.admin["set-contact-address"](), postBody);
    },
    getContactAddress: async () => {
      return await axios.get(routes.admin["get-contact-address"]());
    },
    setContactPhone: async (postBody) => {
      return await axios.post(routes.admin["set-contact-phone"](), postBody);
    },
    getContactPhone: async () => {
      return await axios.get(routes.admin["get-contact-phone"]());
    },
    setAdminPhone: async (postBody) => {
      return await axios.post(routes.admin["set-admin-phone"](), postBody);
    },
    getAdminPhone: async () => {
      return await axios.get(routes.admin["get-admin-phone"]());
    },
    setContactEmail: async (postBody) => {
      return await axios.post(routes.admin["set-contact-email"](), postBody);
    },
    getContactEmail: async () => {
      return await axios.get(routes.admin["get-contact-email"]());
    },
    setAboutUs: async (postBody) => {
      return await axios.post(routes.admin["set-about-us"](), postBody);
    },
    getAboutUs: async () => {
      return await axios.get(routes.admin["get-about-us"]());
    },

    getInActiveUsers: async () => {
      return await axios.get(routes.admin["get-all-inactive-users"]());
    },
    getActiveUsers: async () => {
      return await axios.get(routes.admin["get-all-active-users"]());
    },
    activateUser: async (id) => {
      return await axios.post(routes.admin["activate-user"](id));
    },
    deactivateUser: async (id) => {
      return await axios.post(routes.admin["deactivate-user"](id));
    },
    getCampaignHistory: async () => {
      return await axios.get(routes.admin["get-all-camapaign-history"]());
    },
    getVoiceHistory: async () => {
      return await axios.get(routes.admin["get-all-voice-history"]());
    },
    getAllTransations: async () => {
      return await axios.get(routes.admin["get-transactionHistory"]());
    },
    assignESP: async (id, postBody) => {
      return await axios.post(routes.admin["assign-espee"](id), postBody);
    },
  },
};

export default api;
