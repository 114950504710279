import React, { useState } from "react";
import { Button, Modal, Box, Typography, CircularProgress } from "@mui/material";
import api from "../services/api";
import { toast } from "react-toastify";

const MessageModal = ({ open, onClose, user }) => {
  const [sending, setSending] = useState(false)
  const [message, setMessage] = useState('')

  const sendMessage = () => {
    setSending(true)
    const formData = new FormData()

    formData.append('message', message)
    if(user?.id ){
      formData.append('user_id', user?.id)
    }

    api.admin.sendSMStoUser(formData).then(res=>{
      onClose()
      toast.success(res.data.message)
    }).catch(console.error).finally(()=>setSending(false))
    
  }
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="flex justify-center items-center"
    >
      <Box className="bg-gray-800 p-4 rounded shadow-lg w-full max-w-lg mx-4">
        <Typography id="modal-modal-title" className="text-white my-4" variant="h6">
          Message {user?.username}
        </Typography>
        <Box className="mb-4">
          <textarea
            className="w-full h-32 p-2 bg-gray-900 text-white border border-gray-600 rounded focus:outline-none focus:border-blue-500"
            rows={10}
            placeholder="Type your message here..."
            value={message}
            onChange={(e)=>setMessage(e.target.value)}
          />
        </Box>
        <Box className="flex justify-end space-x-2">
          <Button onClick={onClose} className="text-gray-300 hover:text-white">
            Cancel
          </Button>
          <Button
            onClick={sendMessage}
            className={`bg-blue-600 text-white ${sending ? "cursor-not-allowed" : ""}`}
            disabled={sending}
          >
            {sending ? <CircularProgress /> : "Send"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default MessageModal;
