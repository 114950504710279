import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
} from "react-router-dom";
// Layouts
import DashboardLayout from "./Layouts/DashboardLayout";
import HomeLayout from "./Layouts/HomeLayout";
// Pages
import HomePage from "./Pages/HomePage";
import NotFoundPage from "./Pages/NotFoundPage";
import AboutPage from "./Pages/AboutPage";
import ContactUs from "./Pages/ContactUs";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import Terms from "./Pages/Terms";
// DASHBOARD PAGES
import Dashboard from "./Pages/Dashboard/Dashboard";
import ComposeSMS from './Pages/Dashboard/ComposeSMS'
import SentMessages from './Pages/Dashboard/SentMessages'
// import ScheduledSMS from './Pages/Dashboard/ScheduledSMS'
// import Drafts from './Pages/Dashboard/Drafts'
// import DownloadReportS from './Pages/Dashboard/DownloadReports'
import DeliveryReports from './Pages/Dashboard/DeliveryReports'
import PhoneBook from "./Pages/Dashboard/Contact/PhoneBook";
import BuySP from "./Pages/Dashboard/BuySP";
import Profile from "./Pages/Dashboard/Profile";
import SenderIDRequestForm from "./Pages/Dashboard/SenderIDRequestForm";
import TransactionHistory from "./Pages/Dashboard/TransactionHistory";
// import InsertContact from "./Pages/Dashboard/Contact/InsertContact";
import UploadContact from "./Pages/Dashboard/Contact/UploadContact";
// import { Button } from "@mui/material";
// import WhatsAppIcon from '@mui/icons-material/WhatsApp';
// import Register from "./Pages/Auth/Register";
import LoginPage from "./Pages/Auth/Login";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useUser } from "./Context/UserProvider";
// admin
import SetWallet from "./Pages/Dashboard/Admin/SetWallet";
import SetSMSUnitPrice from "./Pages/Dashboard/Admin/SetSMSUnitPrice";
import SetSmsApiKey from "./Pages/Dashboard/Admin/SetSmsApiKey";
import SetEspeeApiKey from "./Pages/Dashboard/Admin/SetEspeeApiKey";
import ApproveSenderID from "./Pages/Dashboard/Admin/ApproveSenderID";
import LoginLayout from "./Layouts/LoginLayout";
import SetContactAddress from "./Pages/Dashboard/Admin/SetAddress";
import SetContactEmail from "./Pages/Dashboard/Admin/SetEmail";
import SetContactPhone from "./Pages/Dashboard/Admin/SetPhone";
import TextToSpeech from "./Pages/Dashboard/TextToSpeech";
import SetAboutUs from "./Pages/Dashboard/Admin/setAboutUs";
import SetVoiceUnitPrice from "./Pages/Dashboard/Admin/SetVoiceUnitPrice";
import ApproveVirtualNumber from "./Pages/Dashboard/Admin/ApproveVirtualNumber";
import ManageUsers from "./Pages/Dashboard/Admin/ManageUsers";
import CampaignHistory from "./Pages/Dashboard/Admin/CampaignHistory";
import VoiceReports from "./Pages/Dashboard/VoiceReports";
import VoiceReportsAdmin from "./Pages/Dashboard/Admin/VoiceReports";
import Pricing from "./Pages/Dashboard/Pricing";
import AssignESPEES from "./Pages/Dashboard/Admin/AssignESPEES";
import OwnNumber from "./Pages/Dashboard/Admin/OwnNumbers";
import MessageUsers from "./Pages/Dashboard/Admin/MessageUsers";
import Announcement from "./Pages/Dashboard/Admin/Announcement";




function App() {
  const { user } = useUser()

  const isUserLoggedIn = () => user && Object.keys(user).length > 0
  const isAdmin = () => isUserLoggedIn() && user.role === "admin";

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/" element={<HomeLayout />}>
          <Route index element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<Terms />} />
        </Route>
        <Route path='/login' element={<LoginLayout />}>
          <Route index element={<LoginPage />} />
        </Route>
        <Route path="/dashboard" element={!isUserLoggedIn() ? <Navigate to='/' replace /> : <DashboardLayout />}>
          <Route index element={<Dashboard />} exact />
          <Route path="compose-sms" element={<ComposeSMS />} />
          {/* <Route path="scheduled-sms" element={<ScheduledSMS />} /> */}
          <Route path="sent-messages" element={<SentMessages />} />
          <Route path="text-to-speech" element={<TextToSpeech />} />
          <Route path="voice-reports" element={<VoiceReports />} />
          <Route path="delivery-reports" element={<DeliveryReports />} />
          <Route path="phonebook" element={<PhoneBook />} />
          <Route path="upload-contacts" element={<UploadContact />} />
          <Route path="buy-sms-online" element={<BuySP />} />
          <Route path="pricing" element={<Pricing />} />
          <Route path="profile-summary" element={<Profile />} />
          <Route path="request-sender-id" element={<SenderIDRequestForm />} />
          <Route path="transaction-history" element={<TransactionHistory />} />
        </Route>
        <Route path="/dashboard/admin" element={!isAdmin() ? <Navigate to='/login' replace /> : <DashboardLayout />}>
          <Route index element={<Profile />} exact />
          <Route path="set-wallet-address" element={<SetWallet />} />
          <Route path="announcement" element={<Announcement />} />
          <Route path="set-sms-unit-price" element={<SetSMSUnitPrice />} />
          <Route path="set-voice-unit-price" element={<SetVoiceUnitPrice />} />
          <Route path="set-sms-api-key" element={<SetSmsApiKey />} />
          <Route path="set-espee-api-key" element={<SetEspeeApiKey />} />
          <Route path="approve-sender-id" element={<ApproveSenderID />} />
          <Route path="approve-virtualNumber" element={<ApproveVirtualNumber />} />
          <Route path="own-numbers" element={<OwnNumber />} />
          <Route path="manage-users" element={<ManageUsers />} />
          <Route path="message-users" element={<MessageUsers />} />
          <Route path="assign-espees" element={<AssignESPEES />} />
          <Route path="voice-reports" element={<VoiceReportsAdmin />} />
          <Route path="campaign-history" element={<CampaignHistory />} />
          <Route path="set-contact-address" element={<SetContactAddress />} />
          <Route path="set-contact-phone" element={<SetContactPhone />} />
          <Route path="set-contact-email" element={<SetContactEmail />} />
          <Route path="set-pricing" element={<SetAboutUs />} />
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </>
    )
  );
  return (
    <div className="relative min-h-screen">
      <RouterProvider router={router} />
      <ToastContainer autoClose={5000} />
      {/* <div className="fixed bottom-8 right-4">
        <Button variant="contained" href="https://wa.me/9058507523" target="_blank" startIcon={<WhatsAppIcon />} sx={{ backgroundColor: '#2F855A', ":hover": '#60A180' }}>
          WhatsApp
        </Button>
      </div> */}
    </div>
  );
}

export default App;
