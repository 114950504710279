import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  // TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import { toast } from "react-toastify";
import api from "../../../services/api";
import { Editor } from "@tinymce/tinymce-react";

const SetAboutUs = () => {
  const [about, setAbout] = useState("");
  const [loading, setLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const editorRef = useRef(null);

  useEffect(() => {
    setIsFetching(true);
    api.admin
      .getAboutUs()
      .then((res) => {
        setAbout(res.data["About-Us"][0].value);
      })
      .catch((err) => {
        toast.error("Failed to get About Us.");
      })
      .finally(() => {
        setIsFetching(false);
      });
  }, []);


  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append("content", about);

    api.admin
      .setAboutUs(formData)
      .then((res) => {
        console.log(res);
        toast.success("Pricing write up updated successfully!");
      })
      .catch((err) => {
        toast.error("Failed to set pricing details.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleEditorChange = (content) => {
    setAbout(content);
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: '90%',
        margin: "0 auto",
        padding: 2,
        boxShadow: 3,
        borderRadius: 1,
        backgroundColor: "white",
      }}
    >
      <Typography variant="h5" component="h2" gutterBottom>
        Set Pricing
      </Typography>
      {/* {isFetching ? <CircularProgress /> : <TextField
        label="About Us"
        variant="outlined"
        name="about"
        value={about}
        onChange={handleInputChange}
        fullWidth
        required
        multiline
        rows={10}
        sx={{ marginBottom: 2 }}
      />} */}
      {isFetching ? (
        <CircularProgress />
      ) : (
        <Editor
          value={about}
          onEditorChange={handleEditorChange}
          onInit={(_evt, editor) => (editorRef.current = editor)}
          apiKey={"h7z90jhvtz5y1lv7572r90ll3dpb127dktq1pog8lsgglyi1"}
          init={{
            plugins:
              "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed linkchecker a11ychecker tinymcespellchecker permanentpen powerpaste advtable advcode editimage advtemplate ai mentions tinycomments tableofcontents footnotes mergetags autocorrect typography inlinecss markdown",
            toolbar:
              "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
            tinycomments_mode: "embedded",
            tinycomments_author: "Author name",
            ai_request: (request, respondWith) =>
              respondWith.string(() =>
                Promise.reject("See docs to implement AI Assistant")
              ),
            forced_root_block: false,
          }}
          initialValue={about}
        />
      )}
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={loading}
      >
        {loading ? <CircularProgress size={24} /> : "Submit"}
      </Button>
    </Box>
  );
};

export default SetAboutUs;
