import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Paper,
  Typography,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/system";
import api from "../../../services/api";
import { toast } from "react-toastify";
import { formatDate } from "../../../data";
import ReactPaginate from "react-paginate";

const StyledButton = styled(Button)`
  @apply mx-2;
`;

function ManageUsers() {
  const [activeUsers, setActiveUsers] = useState([]);
  const [inactiveUsers, setInactiveUsers] = useState([]);
  const [filter, setFilter] = useState("active");
  const [search, setSearch] = useState("");
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    setIsFetching(true);
    const fetchUsers = async () => {
      try {
        const activeUsersData = await api.admin.getActiveUsers();
        const inactiveUsersData = await api.admin.getInActiveUsers();

        setActiveUsers(activeUsersData.data["active_users"]);
        setInactiveUsers(inactiveUsersData.data["inactive_users"]);
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setIsFetching(false);
      }
    };

    fetchUsers();
  }, []);

  const users = filter === "active" ? activeUsers : inactiveUsers;
  const FilteredUsers = users.filter((user) =>
    (user?.username?.toLowerCase() || '').includes(search.toLowerCase())
  );
  

  const handleActiveness = (user) => {
    if (user.status === "active") {
      api.admin.deactivateUser(user.id)
        .then((res) => {
          toast.success(res.data.message);
          setActiveUsers(activeUsers.filter(u => u.id !== user.id));
          setInactiveUsers([...inactiveUsers, { ...user, status: 'inactive' }]);
        })
        .catch((error) => {
          console.error(error);
          toast.error("Failed to deactivate user.");
        });
    } else {
      api.admin.activateUser(user.id)
        .then((res) => {
          toast.success(res.data.message);
          setInactiveUsers(inactiveUsers.filter(u => u.id !== user.id));
          setActiveUsers([...activeUsers, { ...user, status: 'active' }]);
        })
        .catch((error) => {
          console.error(error);
          toast.error("Failed to activate user.");
        });
    }
  };

  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const pageCount = Math.ceil(FilteredUsers.length / itemsPerPage);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = FilteredUsers.slice(indexOfFirstItem, indexOfLastItem);

  const handleItems = (e) => {
    setItemsPerPage(e.target.value)
  }



  return (
    <section className="w-full md:w-[95%]">
      <div className="my-4">
        <Typography variant="h4">Manage Users</Typography>
      </div>
      <div className="flex justify-center gap-4 mb-4">
        <StyledButton
          variant="contained"
          color="primary"
          onClick={() => setFilter("active")}
        >
          Active
        </StyledButton>
        <StyledButton
          variant="contained"
          color="secondary"
          onClick={() => setFilter("inactive")}
        >
          Inactive
        </StyledButton>
      </div>
      <div className='my-4 w-full md:w-[85%] mx-auto'>
        <input type="search" value={search} name="search" id="search" onChange={(e) => setSearch(e.target.value)} className="border-2 border-[#10439F] rounded-md outline-none p-3 bg-transparent w-full" placeholder="Search for user by their username..." />
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Profile Picture</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Username</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Gender</TableCell>
              <TableCell>Kingschat ID</TableCell>
              <TableCell>Last Login</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          {isFetching ? (
            <TableRow>
              <TableCell colSpan={10} align="center">
                <CircularProgress />
              </TableCell>
            </TableRow>
          ) : (

            <TableBody className='w-full overflow-x-auto'>
              {currentItems.map((user) => (
                <TableRow key={user.id}>
                  <TableCell>{user.id}</TableCell>
                  <TableCell>
                    <img src={user.avatar || 'https://imgs.search.brave.com/J5-KJNoclGIgO9mgbMuULm8xw_ri-hvqZYOyhc50Q64/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly90NC5m/dGNkbi5uZXQvanBn/LzAyLzE3LzM0LzY3/LzM2MF9GXzIxNzM0/Njc4Ml83WHBDVHQ4/YkxOSnF2VkFhRFpK/d3Zaam0wZXBRbWo2/ai5qcGc'} alt={user.name} className='w-10 h-10 rounded-full' />
                  </TableCell>
                  <TableCell>{user.name}</TableCell>
                  <TableCell>{user?.username || 'N/A'}</TableCell>
                  <TableCell>{user?.email || 'N/A'}</TableCell>
                  <TableCell>{user?.phone_number || 'N/A'}</TableCell>
                  <TableCell>{user?.gender || 'N/A'}</TableCell>
                  <TableCell sx={{ width: '30px' }}>{user?.kingschat_id || 'N/A'}</TableCell>
                  <TableCell>{formatDate(user?.last_login_at)}</TableCell>
                  <TableCell>{user.status}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color={user.status === "active" ? "secondary" : "primary"}
                      onClick={() => handleActiveness(user)}
                    >
                      {user.status === "active" ? "Deactivate" : "Activate"}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <div>
        <select name="items" value={itemsPerPage} id="itemsperpage" onChange={handleItems} className="m-4 border border-[#1977CC]">
          <option value={20}>20</option>
          <option value={40}>40</option>
          <option value={60}>60</option>
        </select>
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          containerClassName={"flex justify-center gap-2 my-12"}
          previousLinkClassName={
            "rounded-full border px-4 py-2 mr-2 bg-red-500 text-white"
          }
          nextLinkClassName={
            "rounded-full border px-4 py-2 ml-2 bg-asparagus-500 text-[#1977CC]"
          }
          breakClassName={"text-blue-500 cursor-pointer"}
          activeClassName={
            "bg-blue-500 text-white border rounded-full p-2"
          }
        />
      </div>
    </section>
  );
}

export default ManageUsers;
