import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import api from "../../services/api";
import { formatDate } from "../../data";
import Loader from "../../Components/Loader";
import { toast } from "react-toastify";
import { useUser } from "../../Context/UserProvider";
import ConfirmSendingModal from "../../Components/ConfirmSendingModal";

const ComposeSMS = () => {
  const [sendLater, setSendLater] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [confirming, setIsConfirming] = useState(false);
  const [allGroups, setAllGroups] = useState([]);
  const [senderId, setSenderIDS] = useState([]);
  const [confirmDetails, setConfirmDetails] = useState('');
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [campaign, setCampaign] = useState({
    senderID: "",
    message: "",
    messageType: "text",
    wap_title: "",
    wap_url: "",
    recipients: [],
    time: "",
  });
  const [vcard, setVCard] = useState({
    contacts: [],
    first_name: "",
    last_name: "",
    company: "",
    job_title: "",
    telephone: "",
    email: "",
  });

  const { setEspee, user } = useUser();

  // Calculate the characters left and the number of pages
  const charactersLeft = 156 - (campaign.message.length % 156);
  const pages = Math.ceil(campaign.message.length / 156);

  useEffect(() => {
    if (campaign.recipients.length > 0) {
      setVCard((prev) => ({
        ...prev,
        contacts: JSON.stringify([...campaign.recipients]),
      }));
    }
  }, [campaign.recipients]);

  const ColorButton = styled(Button)(({ theme }) => ({
    color: "white",
    padding: "10px 0",
    "&:hover": {
      backgroundColor: "#4bb5ff",
      color: "white",
    },
  }));

  useEffect(() => {
    setIsLoading(true);
    api.campaign
      .getSenderId()
      .then((res) => setSenderIDS(res.data?.senderId))
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
    api.contacts
      .getGroup()
      .then((res) => {
        setAllGroups(res.data?.groups);
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  }, []);

  const handleCampaignChange = (field, value) => {
    setCampaign({ ...campaign, [field]: value });
  };

  const handleVCardChange = (field, value) => {
    setVCard({ ...vcard, [field]: value });
  };

  const handleRecipientsChange = (value) => {
    setCampaign({ ...campaign, recipients: value.split(",") });
  };
  // Function to handle when a group checkbox is toggled
  const handleGroupToggle = (groupId) => {
    const currentIndex = selectedGroups.indexOf(groupId);
    const newSelectedGroups = [...selectedGroups];

    if (currentIndex === -1) {
      newSelectedGroups.push(groupId);
    } else {
      newSelectedGroups.splice(currentIndex, 1);
    }

    setSelectedGroups(newSelectedGroups);
    Promise.all(
      newSelectedGroups.map((groupId) =>
        api.contacts
          .getContactForSingleGroup(groupId)
          .then((res) => res.data.contacts.map((contact) => contact.phone))
      )
    ).then((phoneNumbersArray) => {
      // Flatten phoneNumbersArray to get a single array of phone numbers
      const newRecipients = phoneNumbersArray.flat();
      setCampaign((prevCampaign) => ({
        ...prevCampaign,
        recipients: [...prevCampaign.recipients, ...newRecipients],
      }));
    });
  };

  const sendSMS = () => {
    setIsSending(true);
    if (user.status === 'inactive') {
      toast.error('Please Contact Admin to activate your account')
      setIsSending(false)
      return;
    }

    // Validation checks
    if (!campaign.senderID) {
      toast.error("Sender ID is required");
      setIsSending(false);
      return;
    }

    if (!campaign.recipients || campaign.recipients.length === 0) {
      toast.error("Recipients are required");
      setIsSending(false);
      return;
    }

    if (!campaign.message && campaign.messageType !== 'vcard' && campaign.messageType !== 'wap-push') {
      toast.error("Message is required");
      setIsSending(false);
      return;
    }

    if (campaign.messageType === "wap-push" && (!campaign.wap_title || !campaign.wap_url)) {
      toast.error("WAP title and URL are required for WAP push messages");
      setIsSending(false);
      return;
    }

    if (campaign.messageType === "vcard") {
      if (!vcard.first_name || !vcard.last_name || !vcard.company || !vcard.job_title || !vcard.telephone || !vcard.email) {
        toast.error("All vCard fields are required");
        setIsSending(false);
        return;
      }
    }

    const formData = new FormData();
    formData.append('senderid', campaign.senderID);

    if (campaign.messageType === "text" || campaign.messageType === "flash") {
      formData.append("message", campaign.message);
      formData.append("contacts", campaign.recipients);
    } else if (campaign.messageType === "wap-push") {
      formData.append("contacts", campaign.recipients);
      formData.append("wap_title", campaign.wap_title);
      formData.append("wap_url", campaign.wap_url);
    } else if (campaign.messageType === "unicode") {
      formData.append("msg", campaign.message);
      formData.append("contacts", campaign.recipients);
    }

    if (sendLater === true) {
      if (campaign.messageType !== "vcard") {
        formData.append("time", campaign.time);
      } else {
        vcard.time = campaign.time;
      }

      if (campaign.messageType === "vcard") {
        api.campaign
          .scheduleSMS(campaign.messageType, vcard)
          .then((res) => {
            setConfirmDetails(res.data?.details)
            setIsConfirming(true)
          })
          .catch((res) => {
            if (res.status === 400) {
              toast.error(res.response.data?.message);
            }
          })
          .finally(() => setIsSending(false));
      } else {
        api.campaign
          .scheduleSMS(campaign.messageType, formData)
          .then((res) => {
            setConfirmDetails(res.data?.details)
            setIsConfirming(true)
          })
          .catch((res) => toast.error(res.response.data?.message))
          .finally(() => setIsSending(false));
      }
    } else {
      if (campaign.messageType === "vcard") {
        api.campaign
          .sendSMS(campaign.messageType, vcard)
          .then((res) => {
            setConfirmDetails(res.data?.details)
            setIsConfirming(true)
          })
          .catch((res) => toast.error(res.response.data?.message))
          .finally(() => setIsSending(false));
      } else {
        api.campaign
          .sendSMS(campaign.messageType, formData)
          .then((res) => {
            setConfirmDetails(res.data?.details)
            setIsConfirming(true)
          })
          .catch((res) => toast.error(res.response.data?.message))
          .finally(() => setIsSending(false));
      }
    }
  };

  const confirmSMS = () => {
    setIsSending(true);
    if (user.status === 'inactive') {
      toast.error('Please Contact Admin to activate your account')
      setIsSending(false)
      return;
    }

    // Validation checks
    if (!campaign.senderID) {
      toast.error("Sender ID is required");
      setIsSending(false);
      return;
    }

    if (!campaign.recipients || campaign.recipients.length === 0) {
      toast.error("Recipients are required");
      setIsSending(false);
      return;
    }

    if (!campaign.message && campaign.messageType !== 'vcard' && campaign.messageType !== 'wap-push') {
      toast.error("Message is required");
      setIsSending(false);
      return;
    }

    if (campaign.messageType === "wap-push" && (!campaign.wap_title || !campaign.wap_url)) {
      toast.error("WAP title and URL are required for WAP push messages");
      setIsSending(false);
      return;
    }

    if (campaign.messageType === "vcard") {
      if (!vcard.first_name || !vcard.last_name || !vcard.company || !vcard.job_title || !vcard.telephone || !vcard.email) {
        toast.error("All vCard fields are required");
        setIsSending(false);
        return;
      }
    }

    const formData = new FormData();
    formData.append('senderid', campaign.senderID);

    if (campaign.messageType === "text" || campaign.messageType === "flash") {
      formData.append("message", campaign.message);
      formData.append("contacts", campaign.recipients);
    } else if (campaign.messageType === "wap-push") {
      formData.append("contacts", campaign.recipients);
      formData.append("wap_title", campaign.wap_title);
      formData.append("wap_url", campaign.wap_url);
    } else if (campaign.messageType === "unicode") {
      formData.append("msg", campaign.message);
      formData.append("contacts", campaign.recipients);
    }

    if (sendLater === true) {
      if (campaign.messageType !== "vcard") {
        formData.append("time", campaign.time);
      } else {
        vcard.time = campaign.time;
      }

      if (campaign.messageType === "vcard") {
        api.campaign
          .confirmScheduleSMS(campaign.messageType, vcard)
          .then((res) => {
            if (res.status === 200) {
              toast.success("SMS Sent");
              setIsConfirming(false)
              api.campaign
                .getEspeeBalance()
                .then((res) => {
                  setEspee(res.data?.["espees-balance"]);
                })
                .catch((err) => console.log(err));
              setSelectedGroups([]);
              setVCard({
                contacts: [],
                first_name: "",
                last_name: "",
                company: "",
                job_title: "",
                telephone: "",
                email: "",
              });
              setCampaign({
                campaign_name: "",
                message: "",
                recipients: [],
                scheduled_at: "",
              });
            }
          })
          .catch((res) => {
            if (res.status === 400) {
              toast.error(res.response.data?.message);
            }
          })
          .finally(() => setIsSending(false));
      } else {
        api.campaign
          .confirmScheduleSMS(campaign.messageType, formData)
          .then((res) => {
            if (res.status === 200) {
              toast.success("SMS Sent");
              setIsConfirming(false)
              api.campaign
                .getEspeeBalance()
                .then((res) => {
                  setEspee(res.data?.["espees-balance"]);
                })
                .catch((err) => console.log(err));
              setSelectedGroups([]);
              setCampaign({
                campaign_name: "",
                message: "",
                recipients: [],
                scheduled_at: "",
              });
            }
          })
          .catch((res) => toast.error(res.response.data?.message))
          .finally(() => setIsSending(false));
      }
    } else {
      if (campaign.messageType === "vcard") {
        api.campaign
          .confirmSMS(campaign.messageType, vcard)
          .then((res) => {
            if (res.status === 200) {
              toast.success("SMS Sent");
              setIsConfirming(false)
              api.campaign
                .getEspeeBalance()
                .then((res) => {
                  setEspee(res.data?.["espees-balance"]);
                })
                .catch((err) => console.log(err));
              setSelectedGroups([]);
              setVCard({
                contacts: [],
                first_name: "",
                last_name: "",
                company: "",
                job_title: "",
                telephone: "",
                email: "",
              });
              setCampaign({
                campaign_name: "",
                message: "",
                recipients: [],
                scheduled_at: "",
              });
            }
          })
          .catch((res) => toast.error(res.response.data?.message))
          .finally(() => setIsSending(false));
      } else {
        api.campaign
          .confirmSMS(campaign.messageType, formData)
          .then((res) => {
            console.log(res)
            if (res.status === 200) {
              toast.success("SMS Sent");
              setIsConfirming(false)
              api.campaign
                .getEspeeBalance()
                .then((res) => {
                  setEspee(res.data?.["espees-balance"]);
                })
                .catch((err) => console.log(err));
              setSelectedGroups([]);
              setCampaign({
                campaign_name: "",
                message: "",
                recipients: [],
                scheduled_at: "",
              });
            }
          })
          .catch((res) => toast.error(res.response.data?.message))
          .finally(() => setIsSending(false));
      }
    }
  };


  return (
    <section className="composeSMS">
      <div className="w-full md:w-[60%] lg:w-[70%] shadow-lg px-[10px] py-[20px]">
        <Container>
          <h2>Compose Message</h2>
          <div className="flex flex-col gap-3 my-4">
            <FormControl fullWidth>
              <InputLabel id="senderID-select">Sender ID</InputLabel>
              <Select
                labelId="senderID-select"
                id="senderID"
                value={campaign.senderID}
                label="Sender ID"
                onChange={(e) =>
                  handleCampaignChange("senderID", e.target.value)
                }
              >
                {/* <MenuItem value={""}>Select a Sender ID</MenuItem> */}
                <MenuItem value={"WEBSMS"}>Default Sender ID</MenuItem>
                {senderId.map((id) => {
                  return (
                    <MenuItem key={id.id} value={id.sender_id}>
                      {id.sender_id}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <TextField
              id="recipients"
              label="Recipients"
              multiline
              rows={5}
              placeholder="Numbers eg(801234567,814325679)"
              value={campaign.recipients.join(",")}
              onChange={(e) => handleRecipientsChange(e.target.value)}
            />
            <FormControl fullWidth>
              <InputLabel id="message-type-select">Message Type</InputLabel>
              <Select
                labelId="message-type-select"
                id="messageType"
                value={campaign.messageType}
                label="Message Type"
                onChange={(e) =>
                  handleCampaignChange("messageType", e.target.value)
                }
              >
                <MenuItem value={"text"}>Text SMS</MenuItem>
                <MenuItem value={"flash"}>Flash SMS</MenuItem>
                <MenuItem value={"wap-push"}>Wap Push SMS</MenuItem>
                <MenuItem value={"vcard"}>Vcard SMS</MenuItem>
                <MenuItem value={"unicode"}>Unicode SMS</MenuItem>
              </Select>
            </FormControl>
            {campaign.messageType !== 'vcard' && campaign.messageType !== 'wap-push' && (<div>
              <TextField
                id="message"
                required
                label="Message"
                multiline
                rows={5}
                placeholder="Your message here..."
                helperText="This box is required"
                fullWidth
                value={campaign.message}
                onChange={(e) =>
                  handleCampaignChange("message", e.target.value)
                }
              />
              <article className="flex justify-between my-3">
                <p>
                  Characters left: <span>{charactersLeft}/156</span>
                </p>
                <p>
                  Page(s):<span>{pages}</span>
                </p>
              </article>
            </div>)}
          </div>
          {campaign.messageType === "vcard" && (
            <div>
              <div className="flex flex-col md:flex-row justify-start gap-3 mb-3">
                <TextField
                  id="first_name"
                  label="First Name"
                  variant="outlined"
                  placeholder="Enter First Name"
                  value={vcard.first_name}
                  fullWidth
                  onChange={(e) =>
                    handleVCardChange("first_name", e.target.value)
                  }
                />
                <TextField
                  id="last_name"
                  label="Last Name"
                  variant="outlined"
                  placeholder="Enter Last Name"
                  value={vcard.last_name}
                  fullWidth
                  onChange={(e) =>
                    handleVCardChange("last_name", e.target.value)
                  }
                />
              </div>
              <div className="flex flex-col md:flex-row justify-start gap-3 mb-3">
                <TextField
                  id="company"
                  label="Company"
                  variant="outlined"
                  placeholder="Enter Company"
                  value={vcard.company}
                  fullWidth
                  onChange={(e) => handleVCardChange("company", e.target.value)}
                />
                <TextField
                  id="job_title"
                  label="Job Title"
                  variant="outlined"
                  placeholder="Enter Job Title"
                  value={vcard.job_title}
                  fullWidth
                  onChange={(e) =>
                    handleVCardChange("job_title", e.target.value)
                  }
                />
              </div>
              <div className="flex flex-col md:flex-row justify-start gap-3 mb-3">
                <TextField
                  id="email"
                  label="Email"
                  variant="outlined"
                  placeholder="Enter Email"
                  type="email"
                  value={vcard.email}
                  fullWidth
                  onChange={(e) => handleVCardChange("email", e.target.value)}
                />
                <TextField
                  id="telephone"
                  label="Telephone"
                  variant="outlined"
                  placeholder="Enter telephone"
                  type="tel"
                  value={vcard.telephone}
                  fullWidth
                  onChange={(e) =>
                    handleVCardChange("telephone", e.target.value)
                  }
                />
              </div>
            </div>
          )}
          {campaign.messageType === "wap-push" && (
            <div className="flex flex-col md:flex-row justify-start gap-3 mb-3">
              <TextField
                id="wap-title"
                label="Wap title"
                variant="outlined"
                placeholder="Enter wap title"
                value={campaign.wap_title}
                fullWidth
                onChange={(e) =>
                  handleCampaignChange("wap_title", e.target.value)
                }
              />

              <TextField
                id="wap-url"
                label="Wap URL"
                variant="outlined"
                placeholder="Enter wap url"
                value={campaign.wap_url}
                fullWidth
                onChange={(e) =>
                  handleCampaignChange("wap_url", e.target.value)
                }
              />
            </div>
          )}
          <FormControlLabel
            control={
              <Checkbox
                checked={sendLater}
                onChange={() => setSendLater(!sendLater)}
                name="Send Later"
              />
            }
            label="Send Later"
          />
          {sendLater && (
            <article className="mb-3">
              <div className="flex flex-col md:flex-row justify-start gap-3 mb-3">
                <TextField
                  hiddenLabel
                  id="filled-hidden-label-normal"
                  defaultValue="Normal"
                  variant="outlined"
                  type="datetime-local"
                  fullWidth
                  value={campaign.time}
                  onChange={(e) =>
                    handleCampaignChange("time", formatDate(e.target.value))
                  }
                />
              </div>
            </article>
          )}
          {sendLater && (
            <ColorButton variant="contained" onClick={sendSMS} fullWidth>
              {isSending ? <Loader /> :'Schedule SMS'}
            </ColorButton>
          )}
          {!sendLater && (
            <div className="flex flex-col gap-4">
              <ColorButton
                variant="contained"
                disabled={isSending}
                onClick={sendSMS}
                fullWidth
              >
                {isSending ? <Loader /> : "Send Message"}
              </ColorButton>
              {/* <ColorButton
                variant="outlined"
                sx={{ color: "#4bb5ff" }}
                onClick={""}
                fullWidth
                disabled={isSending}
              >
                {isSending ? <Loader /> : "Save as draft"}
              </ColorButton> */}
            </div>
          )}
        </Container>
      </div>
      <div className="w-full md:w-[40%] lg:w-[30%] shadow-lg p-[10px] h-full">
        <Container>
          <h2>Select Contact</h2>
          {isLoading ? (
            <div className="flex justify-center items-center h-full my-[40px]">
              <Loader />
            </div>
          ) : (
            <Box>
              {allGroups.map((group, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      checked={selectedGroups.includes(group.id)}
                      onChange={() => handleGroupToggle(group.id)}
                      name={group.group_name}
                    />
                  }
                  label={`${group.group_name} (${group.contacts_count})`}
                />
              ))}
            </Box>
          )}
        </Container>
      </div>
      {
        confirming && <ConfirmSendingModal sending={isSending} open={confirming} handleClose={()=>setIsConfirming(false)} content={campaign} {...confirmDetails} confirm={confirmSMS}/>
      }
    </section>
  );
};

export default ComposeSMS;
